import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Gwlp from "src/components/gwlp"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"

const downloadData = import("./data/data.json");

export default function spadownload() {

  let disclosure = null;
  if(isChrome()){
    disclosure = <CtaDisclosureNewtab data={downloadData}></CtaDisclosureNewtab>;
  }
  else{
    disclosure = <CtaDisclosureNewtab data={downloadData}></CtaDisclosureNewtab>;
  }

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
         #ctadisclosurenewtab-module--ctadisclaimer {
           margin: 25px auto!important;
           width: 80%;
         }
          #ctadisclosurenewtab-module--ctadisclaimer p {
            color: #666!important;
            font-size: 12px!important;
            text-align: center;
          }

          img#cwsbadge {
            margin: 15px auto 0;
            display: block;
            cursor: pointer;
          }
    `}
    </style>

    <title>Track your Water Intake | GetSipApp.com</title></Helmet>
    <section>
      <Gwlp data={downloadData}>
      {disclosure}
    </Gwlp>
      </section>
    </HomepageLayout>
  )
}
